
import { defineComponent } from 'vue';
import BuetifyFooter from './components/buetifyFooter/BuetifyFooter.vue';

export default defineComponent({
	name: 'buetify',
	components: {
		BuetifyFooter
	}
});
