
import BColumn from 'buetify/lib/components/layout/columns/BColumn';
import BColumns from 'buetify/lib/components/layout/columns/BColumns';
import BContainer from 'buetify/lib/components/layout/container/BContainer';
import BContent from 'buetify/lib/components/layout/content/BContent';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'buetify-footer',
	components: {
		BContainer,
		BContent,
		BColumns,
		BColumn
	}
});
